import { Hero, Section, Services, Slider } from "../../components";
import About from "../About";
import Contact from "../Contact";

const Home = () => {
  return (
    <div>
      <Hero />
      <Section />
      <Slider />
      <div className="h-[5px] bg-gray-200 w-full rounded" />
      <About />
      <Services />
      <Contact />
    </div>
  );
};

export default Home;
