import alsina from "../../assets/alsina.png";
import amailla from "../../assets/amailla.png";

const Section = () => {
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32" id="about">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid items-center max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:ml-auto lg:pl-4 lg:pt-4 col-span-2">
            <p className="mt-2 text-3xl font-bold sm:text-5xl text-center pb-10 tracking-wider uppercase">
              Quiénes somos
            </p>
            <p className="mt-6 text-2xl leading-8 text-gray-600 text-center">
              Empresa líder en servicios integrales de almacenaje, con más de 15
              años de trayectoria comprometidos con la calidad de servicio y el
              profesionalismo de nuestro equipo al servicio de nuestros
              clientes.
            </p>
          </div>
        </div>
        <div className="mt-20">
          <p className="mt-2 mb-20 text-4xl font-bold tracking-tight text-pampa-blue text-center">
            Ubicados en Tucumán
          </p>
          <div className="flex flex-col md:flex-row items-center gap-4 mt-4 text-center">
            <div className="w-full border border-pampa-blue shadow-xl rounded-xl p-6">
              <p className="font-playFairBold text-3xl text-gray-500 pb-4">
                DEPÓSITO ALSINA
              </p>
              <img
                src={alsina}
                className="w-full rounded-xl"
                alt="Depósito Alsina - Arglog S.A."
              />
              <ul className="text-xl">
                <li>+10.000 m2</li>
                <li>PT / HELADERAS / REPROCESOS / ENVASES / INSUMOS</li>
              </ul>
            </div>
            <div className="w-full border border-pampa-blue shadow-xl rounded-xl p-6">
              <p className="font-playFairBold text-3xl text-gray-500 pb-4">
                DEPÓSITO FAMAILLÁ
              </p>
              <img
                src={amailla}
                className="w-full rounded-xl"
                alt="Depósito Famaillá - Arglog S.A."
              />
              <ul className="text-xl">
                <li>+3500 m2</li>
                <li>PT / REPROCESOS / ENVASES / INSUMOS</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section;
