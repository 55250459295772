import warehouse from "../../assets/warehouse.jpg";
import warehouseMobile from "../../assets/warehouse-mobile.png";

const Hero = () => {
  return (
    <div id="home">
      <section
        className="hidden relative rounded-md h-[90vh] w-full mt-24 gap-4 lg:flex flex-col justify-center items-center bg-cover bg-center bg-no-repeat bg-fixed"
        style={{ backgroundImage: `url('${warehouse}')` }}
      >
        <div className="absolute top-0 left-0 h-full w-full bg-black opacity-40 z-20 rounded-md" />
        <div className="relative z-30 p-10">
          <h1 className="text-white text-3xl lg:text-8xl text-center tracking-wider drop-shadow-pampa uppercase">
            Arglog SAS
          </h1>
          <h2 className="text-white text-2xl lg:text-6xl drop-shadow-pampa text-center pt-4 uppercase">
            <i>Warehouse Services</i>
          </h2>
        </div>
      </section>
      <section className="relative rounded-md h-[70vh] w-full mt-24 gap-4 flex lg:hidden flex-col justify-center items-center">
        <div className="absolute top-0 left-0 h-full w-full bg-black opacity-30 z-20 rounded-md" />
        <img
          src={warehouseMobile}
          className="absolute top-0 left-0 h-full w-full z-10 rounded-md"
          alt="Pampa Negocios y Garantías S.A."
        />
        <div className="relative z-30 p-10">
          <h1 className="text-white text-3xl lg:text-7xl text-center tracking-wider drop-shadow-pampa uppercase">
            Arglog SAS
          </h1>
          <h2 className="text-white text-xl lg:text-3xl drop-shadow-pampa text-center pt-4">
            <i>Warehouse Services</i>
          </h2>
        </div>
      </section>
    </div>
  );
};

export default Hero;
