import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import compromise from "../../assets/compromise.jpg";
import verified from "../../assets/verified.svg";
import engineering from "../../assets/engineering.svg";
import eco from "../../assets/eco.svg";
import stocks from "../../assets/stocks.svg";
import people from "../../assets/people.svg";
import infra from "../../assets/infra.svg";
import pilares from "../../assets/pilares.png";

const security = [
  "Uso de Elementos de Protección Personal.",
  "Segregación de Hombre / Máquina Seguridad en circulación.",
  "Levantamiento de comportamientos inseguros/seguros.",
  "Flujo de consecuencias.",
  "Evaluación de Riesgos de las tareas.",
  "Uso de Matafuegos.",
  "Seguridad patrimonial física e inalámbrica. Monitoreo con cámaras.",
  "Seguridad en circulación",
  "Monitoreo semanal",
  "Checklist 360 camiones",
  "Levantamientos en Guardian",
];

const qualityControl = [
  "Certificación de calidad.",
  "Política de frescura y rotación.",
  "Tratamiento de No Conformes tanto de producto terminado como de Insumos.",
  "Levantamiento de Incidentes Interplantas.",
  "Manejo integral de Plagas.",
  "Limpieza y mantenimiento de las instalaciones.",
  "Limpieza & 5S",
  "Cultura de BPM",
];

const logisticsInventory = [
  "Layouts definidos. / Trazabilidad por camión y por estiba.",
  "Clasificación de productos ABC.",
  "Gestión por rotura / Seguimiento del KPI rotura.",
  "Inventarios cíclicos y mensuales.",
  "Procedimiento inventarios",
  "Regularización ajustes pendientes",
  "Programación diaria",
];

const wasteManagement = [
  "Seguimiento y difusión indicadores",
  "Rutina de equipo",
  "Dashboard de KPIs ",
  "Segregación de residuos.",
  "Reciclaje y reutilización.",
  "Green Logistics / Banco de Alimentos.",
];

const group = [
  "Roles y responsabilidades",
  "Evaluación de competencias ",
  "Encuesta de clima",
];

const layoutAndInfrastructure = [
  "Demarcaciones",
  "Análisis ABC",
  "Oportunidades de mejora layout",
  "Cuidados de producto e insumos",
  "Limpieza del depósito",
  "Plan de tráfico",
  "Revisión del layout",
];

const Services = () => {
  return (
    <div className="bg-gray-200 rounded-xl py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-3xl text-center font-bold sm:text-5xl pb-20 tracking-wider">
          NUESTRO COMPROMISO
        </h2>
        <div className="mx-auto flex flex-col md:flex-row max-w-2xl items-center gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none">
          <div className="lg:pr-4 w-full">
            <div className="relative overflow-hidden rounded-3xl bg-gray-900 px-6 pb-9 pt-64 shadow-2xl sm:px-12 lg:max-w-lg lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
              <img
                alt="Nuestro compromiso - Arglog S.A."
                src={compromise}
                className="absolute inset-0 h-full w-full object-cover brightness-125 saturate-0"
              />
              <div
                aria-hidden="true"
                className="absolute left-1/2 top-1/2 -ml-16 -translate-x-1/2 -translate-y-1/2 transform-gpu blur-3xl"
              >
                <div
                  style={{
                    clipPath:
                      "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                  }}
                  className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-pampa-lightBlue to-pampa-blue opacity-40"
                />
              </div>
            </div>
          </div>
          <div className="w-full">
            <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
              <div className="max-w-xl">
                <p className="text-2xl text-center leading-8 text-gray-600">
                  En Arglog estamos comprometidos con un futuro sostenible. Por
                  eso nos alineamos con Green Logistics, con el objetivo de
                  proteger el medio ambiente y reafirmar nuestro compromiso con
                  una logística sustentable.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8" id="services">
        <h2 className="mt-20 text-3xl text-center font-bold sm:text-5xl pb-10 tracking-wider">
          NUESTRO DIFERENCIAL
        </h2>
        <div className="w-full">
          <div className="text-base leading-7 text-gray-700">
            <p className="text-2xl text-center leading-8 text-gray-600 pb-10">
              Formamos parte del programa anual WPO , con el objetivo de lograr
              los mejores resultados y estar alineados con la cultura y política
              de AB InBev.
            </p>
          </div>
        </div>
        <div
          style={{
            backgroundImage: `url(${engineering})`,
            backgroundSize: "40%",
          }}
          className="shadow-md p-4 rounded-xl border border-pampa-blue flex flex-col md:flex-row items-center gap-3 justify-center mb-4 min-h-96 bg-white relative bg-no-repeat md:bg-left"
        >
          <div className="w-full text-3xl text-center font-bold tracking-wider uppercase text-pampa-blue">
            Seguridad
          </div>
          <ul className="w-full">
            {security.map((sec) => (
              <li key={sec} className="flex items-center gap-3 text-gray-700">
                <CheckBadgeIcon className="h-5 w-5 text-pampa-lightBlue" />{" "}
                {sec}
              </li>
            ))}
          </ul>
        </div>
        <div
          style={{
            backgroundImage: `url(${verified})`,
            backgroundSize: "40%",
          }}
          className="shadow-md p-4 rounded-xl border border-pampa-blue flex flex-col md:flex-row items-center gap-3 justify-center mb-4 min-h-96 bg-white relative bg-no-repeat md:bg-right"
        >
          <div className="md:hidden w-full text-3xl text-center font-bold tracking-wider uppercase text-pampa-blue">
            CALIDAD
          </div>
          <ul className="w-full">
            {qualityControl.map((sec) => (
              <li key={sec} className="flex items-center gap-3 text-gray-700">
                <CheckBadgeIcon className="h-5 w-5 text-pampa-lightBlue" />{" "}
                {sec}
              </li>
            ))}
          </ul>
          <div className="hidden md:block w-full text-3xl text-center font-bold tracking-wider uppercase text-pampa-blue">
            CALIDAD
          </div>
        </div>
        <div
          style={{
            backgroundImage: `url(${stocks})`,
            backgroundSize: "40%",
          }}
          className="shadow-md p-4 rounded-xl border border-pampa-blue flex flex-col md:flex-row items-center gap-3 justify-center mb-4 min-h-96 md:min-h-72 bg-white relative bg-no-repeat md:bg-left"
        >
          <div className="w-full text-3xl text-center font-bold tracking-wider uppercase text-pampa-blue">
            GESTIÓN DE STOCKS
          </div>
          <ul className="w-full">
            {logisticsInventory.map((sec) => (
              <li key={sec} className="flex items-center gap-3 text-gray-700">
                <CheckBadgeIcon className="h-5 w-5 text-pampa-lightBlue" />{" "}
                {sec}
              </li>
            ))}
          </ul>
        </div>
        <div
          style={{
            backgroundImage: `url(${eco})`,
            backgroundSize: "40%",
          }}
          className="shadow-md p-4 rounded-xl border border-pampa-blue flex flex-col md:flex-row items-center gap-3 justify-center mb-4 min-h-96 md:min-h-72 bg-white relative bg-no-repeat md:bg-right"
        >
          <div className="md:hidden w-full text-3xl text-center font-bold tracking-wider uppercase text-pampa-blue">
            Gestión & NDS
          </div>
          <ul className="w-full">
            {wasteManagement.map((sec) => (
              <li key={sec} className="flex items-center gap-3 text-gray-700">
                <CheckBadgeIcon className="h-5 w-5 text-pampa-lightBlue" />{" "}
                {sec}
              </li>
            ))}
          </ul>
          <div className="hidden md:block w-full text-3xl text-center font-bold tracking-wider uppercase text-pampa-blue">
            Gestión & NDS
          </div>
        </div>
        <div
          style={{
            backgroundImage: `url(${people})`,
            backgroundSize: "40%",
          }}
          className="shadow-md p-4 rounded-xl border border-pampa-blue flex flex-col md:flex-row items-center gap-3 justify-center mb-4 min-h-96 md:min-h-72 bg-white relative bg-no-repeat md:bg-left"
        >
          <div className="w-full text-3xl text-center font-bold tracking-wider uppercase text-pampa-blue">
            GENTE
          </div>
          <ul className="w-full">
            {group.map((sec) => (
              <li key={sec} className="flex items-center gap-3 text-gray-700">
                <CheckBadgeIcon className="h-5 w-5 text-pampa-lightBlue" />{" "}
                {sec}
              </li>
            ))}
          </ul>
        </div>
        <div
          style={{
            backgroundImage: `url(${infra})`,
            backgroundSize: "40%",
          }}
          className="shadow-md p-4 rounded-xl border border-pampa-blue flex flex-col md:flex-row items-center gap-3 justify-center mb-4 min-h-96 md:min-h-72 bg-white relative bg-no-repeat md:bg-right"
        >
          <div className="md:hidden w-full text-3xl text-center font-bold tracking-wider uppercase text-pampa-blue">
            Infraestructura & layout
          </div>
          <ul className="w-full">
            {layoutAndInfrastructure.map((sec) => (
              <li key={sec} className="flex items-center gap-3 text-gray-700">
                <CheckBadgeIcon className="h-5 w-5 text-pampa-lightBlue" />{" "}
                {sec}
              </li>
            ))}
          </ul>
          <div className="hidden md:block w-full text-3xl text-center font-bold tracking-wider uppercase text-pampa-blue">
            Infraestructura & layout
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <img
          alt="Warehouse process optimization - Principales GAPs destacados POR PILAR - Arglog SAS"
          src={pilares}
          className="w-full rounded-xl"
        />
      </div>
    </div>
  );
};

export default Services;
