import { NavLinksInterface } from "../../types";

const NavItems = ({
  links,
  onClick,
}: {
  links: NavLinksInterface[];
  onClick: () => void;
}) => {
  return (
    <>
      <div className="flex lg:hidden flex-col gap-4 lg:flex-row lg:items-center">
        {links.map((link: NavLinksInterface) => (
          <a
            href={link.path}
            key={link.id}
            className="uppercase hover:text-pampa-lightBlue font-bold tracking-wider"
            onClick={onClick}
          >
            {link.name}
          </a>
        ))}
      </div>
      <div className="hidden lg:flex gap-4 items-center">
        {links.map((link: NavLinksInterface) => (
          <a
            href={link.path}
            key={link.id}
            className="uppercase hover:text-pampa-lightBlue font-bold tracking-wider"
          >
            {link.name}
          </a>
        ))}
      </div>
    </>
  );
};

export default NavItems;
