import agrocereales from "../../assets/slide1.png";
import arcor from "../../assets/slide2.png";
import bagley from "../../assets/slide3.png";
import bancociudad from "../../assets/slide4.png";
import bancomf from "../../assets/slide5.png";
import bbva from "../../assets/slide6.png";
import budeguer from "../../assets/slide7.png";
import coopagroTandil from "../../assets/slide8.png";
import cota from "../../assets/slide9.png";
import espatina from "../../assets/slide10.png";
import galicia from "../../assets/slide11.png";
import icbc from "../../assets/slide12.png";

const icons = [
  { id: 1, src: agrocereales, alt: "Arglog SAS - imagen 1" },
  { id: 2, src: arcor, alt: "Arglog SAS - imagen 2" },
  { id: 3, src: bagley, alt: "Arglog SAS - imagen 3" },
  { id: 4, src: bancociudad, alt: "Arglog SAS - imagen 4" },
  { id: 5, src: bancomf, alt: "Arglog SAS - imagen 5" },
  { id: 6, src: bbva, alt: "Arglog SAS - imagen 6" },
  { id: 7, src: budeguer, alt: "Arglog SAS - imagen 7" },
  { id: 8, src: coopagroTandil, alt: "Arglog SAS - imagen 8" },
  { id: 9, src: cota, alt: "Arglog SAS - imagen 9" },
  { id: 10, src: espatina, alt: "Arglog SAS - imagen 10" },
  { id: 11, src: galicia, alt: "Arglog SAS - imagen 11" },
  { id: 12, src: icbc, alt: "Arglog SAS - imagen 12" },
];

const Slider = ({ title }: { title?: boolean }) => {
  return (
    <div className="overflow-hidden bg-white pb-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        {title && (
          <h3 className="mt-2 text-3xl font-bold text-gray-900 sm:text-5xl text-center pb-10 tracking-wider">
            NUESTROS CLIENTES
          </h3>
        )}
        <div
          x-data="{}"
          x-init="$nextTick(() => {
                        let ul = $refs.logos;
                        ul.insertAdjacentHTML('afterend', ul.outerHTML);
                        ul.nextSibling.setAttribute('aria-hidden', 'true');
                    })"
          className="w-full inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)] pb-10"
        >
          <ul
            x-ref="logos"
            className="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll"
          >
            {icons.map((icon) => (
              <li key={icon.id}>
                <img
                  className="w-[200px] md:w-[400px] rounded-md"
                  src={icon.src}
                  alt={icon.alt}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Slider;
