import Logo from "../../assets/arglog-logo.png";
import NavItems from "../NavItems";
import { links } from "../../shared";

const Navbar = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className="w-full h-20 shadow-lg fixed top-0 left-0 z-50 bg-white">
      <nav className="hidden lg:flex w-full h-full items-center justify-between px-2 max-w-7xl mx-auto">
        <a href="#home">
          <img
            src={Logo}
            className="h-16 rounded cursor-pointer"
            alt="Pampa Negocios y Garantías S.A."
          />
        </a>
        <div className="flex items-center gap-4">
          <NavItems links={links} onClick={onClick} />
        </div>
      </nav>
      <nav className="lg:hidden h-full">
        <div className="flex items-center h-full justify-between p-4">
          <a href="#home">
            <img
              src={Logo}
              className="h-10 rounded"
              alt="Pampa Negocios y Garantías S.A."
            />
          </a>
          <button className="text-pampa-lightBlue" onClick={onClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
