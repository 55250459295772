import { useState } from "react";
import { Footer, Navbar, SideBar } from "..";
import NavItems from "../NavItems";
import { links } from "../../shared";
import whatsapp from "../../assets/whatsapp-icon.png";

const Layout = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    if (!open) {
      setOpen(!open);
    } else {
      setTimeout(() => {
        setOpen(!open);
      }, 750);
    }
  };

  return (
    <div>
      <Navbar onClick={handleClick} />
      <SideBar open={open} setOpen={setOpen}>
        <NavItems links={links} onClick={handleClick} />
      </SideBar>
      <main className="w-[98%] mx-auto">
        {children}
        <a
          className="fixed bottom-5 right-4 z-50"
          href="https://wa.me/5493815086064"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="h-14 w-14"
            src={whatsapp}
            alt="Whatsapp | Pampa Negocios y Garantías"
          />
        </a>
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
